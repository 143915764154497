import React, { useState, useEffect } from "react";
import { Form, Input, Button, Card, Select, Row, Col, message, Spin } from "antd";
import { useDispatch } from "react-redux";
import { ApplyCuponCode, GeneratePaymentLink, getPlan, GetPlans } from "../redux/actions/AuthAction";
import useDebounce from '../hooks/useDebounce';

const PaymentLink = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    amount: null,
    discountAmount: null, // Initialize discountAmount as null
    planDuration: "",
    frequency: "",
    numberOfMonths: "",
    formData: "",
  });
  const debouncedSearchTerm = useDebounce(formData.phoneNumber, 900); // 900ms delay
  const [paymentLink, setPaymentLink] = useState(null);
  const [plans, setPlans] = useState(null);
  const [uniqueFrequencies, setUniqueFrequencies] = useState([]);
  const [uniqueDurations, setUniqueDurations] = useState([]);
  const [months, setMonths] = useState([]);
  const [couponCode, setCouponCode] = useState("");
  const [couponApplied, setCouponApplied] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [loader, setLoader] = useState(false);
  const [couponData, setCouponData] = useState(null);
  const [disableCouponInput, setDisableCouponInput] = useState(null);
  const [planLoader, setPlanLoader] = useState(false);
  const [discountValue, setDiscountValue] = useState(null); // New state for discount input
  const [isDiscountApplied, setIsDiscountApplied] = useState(false); // New state for discount applied
  const [appliedDiscountMessage, setAppliedDiscountMessage] = useState("");
  const [dynamicDiscount, setDynamicDiscount] = useState(0);
  const [dynamicPricing, setDynamicPricing] = useState(false);
  const handleApplyCoupon = (couponCode) => {
    setLoader(true);
    setCouponCode(couponCode);
    setCouponApplied(true);
    setDisableCouponInput(true);
    console.log(selectedPlan, formData);
    const data = {
      cupon: couponCode,
      planId: selectedPlan._id,
      subject: "English",
      months: formData.numberOfMonths,
      newPrice: formData.discountAmount,
      phoneNumber: formData.phoneNumber
    };
    const onSuccess = (res) => {
      setLoader(false);
      console.log(res);
      setCouponData(res);
    };
    const onError = (err) => {
      setLoader(false);
      message.error(err);
      setCouponCode("");
      setCouponData(null);
      setCouponApplied(false);
      setDisableCouponInput(false);
      console.log(err);
    };
    dispatch(ApplyCuponCode(data, onSuccess, onError));
  };

  const handleRemoveCoupon = () => {
    setCouponCode("");
    setCouponData(null);
    setCouponApplied(false);
    setDisableCouponInput(false); // Enable input when coupon removed
  };

  // Use debouncedSearchTerm for API calls or other expensive operations
  useEffect(() => {
    // console.log('Debounced search term:', debouncedSearchTerm);
    if (formData?.phoneNumber?.length > 1) {
      setPlanLoader(true);
      const onSuccess = (data) => {
        const filteredPlans = data?.plansData?.filter(
          (plan) => plan.classDuration === "15min" || plan.classDuration === "25min"
        );

        const durations = filteredPlans.map((plan) => plan.classDuration);
        setUniqueDurations([...new Set(durations)])
        setPlans(filteredPlans);
        setDynamicPricing(data?.dynamicPricing);
        console.log("data", data);
        setPlanLoader(false);
      };
      dispatch(GetPlans(formData?.phoneNumber, onSuccess));
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    const ongetPlanSuccess = (data) => {
      setMonths(data);
      setUniqueDurations(data.durations);
      console.log("data2", data.frequencies);
    };
    dispatch(getPlan(ongetPlanSuccess));
  }, [dispatch]);

  useEffect(() => {
    if (formData.planDuration && plans) {
      const selectedPlan = plans.find((plan) => plan.classDuration === formData.planDuration);
      if (selectedPlan) {
        const frequencies = plans
          .filter((plan) => plan.classDuration === selectedPlan.classDuration)
          .map((plan) => plan.frequency);
        setUniqueFrequencies([...new Set(frequencies)]);
      }
    }
  }, [formData.planDuration, plans]);

  const calculateGST = (amount) => {
    return amount ? Math.round(amount * 0.18) + amount : 0;
  };

  useEffect(() => {
    let amount = calculateAmount(
      formData.planDuration,
      formData.frequency,
      formData.numberOfMonths
    );
    let discountAmount = calculateDiscountedAmount(amount, formData.numberOfMonths);
    if (couponData) {
      console.log(
        "if coupon",
        amount,
        couponData.cuponDiscount,
        Math.round((discountAmount * couponData.cuponDiscount) / 100)
      );
      discountAmount =
        discountAmount - Math.round((discountAmount * couponData.cuponDiscount) / 100);
    }
    else if(dynamicDiscount){
      if(!dynamicPricing && dynamicDiscount>=35){
        amount = Math.round(amount*110/100);
        discountAmount = calculateDiscountedAmount(amount, formData.numberOfMonths);
        discountAmount = discountAmount - Math.round((discountAmount * dynamicDiscount) / 100);
      }
      else {
        discountAmount = discountAmount - Math.round((discountAmount * dynamicDiscount) / 100);
      }
    }
    const gst = calculateGST(discountAmount);
    setFormData((prevData) => ({ ...prevData, amount, discountAmount, gst })); // Update amount and discountAmount whenever relevant data changes
  }, [formData.planDuration, formData.frequency, formData.numberOfMonths, couponData, dynamicDiscount, dynamicPricing, plans]);

  useEffect(()=>{
    setPaymentLink(null);
  }, [formData.planDuration, formData.frequency, formData.numberOfMonths, couponData, dynamicDiscount, debouncedSearchTerm]);

  const calculateAmount = (planDuration, frequency, numberOfMonths) => {
    if (plans && planDuration && frequency && numberOfMonths) {
      const selectedPlan = plans.find(
        (plan) => plan.classDuration === planDuration && plan.frequency === frequency
      );
      setSelectedPlan(selectedPlan);
      if (selectedPlan) {
        let amount = selectedPlan.price * numberOfMonths;
        return amount;
      }
    }
    return null;
  };

  const calculateDiscountedAmount = (amount, numberOfMonths) => {
    if (months && months.months) {
      const selectedMonth = months.months.find((month) => month.no === numberOfMonths);
      if (selectedMonth) {
        const discount = selectedMonth.discount;
        const discountedAmount = amount * (1 - discount / 100);
        return discountedAmount;
      }
    }
    return null;
  };

  const handleSubmit = (values) => {
    setLoader(true);
    const { name, email, planDuration, frequency, numberOfMonths, phoneNumber, pin } = values;
    const amount = formData.amount;
    const discountAmount = formData.discountAmount;

    console.log(discountAmount, phoneNumber, pin, name);
    if (!selectedPlan) {
      console.log("no plan selected");
      return;
    }
    console.log(selectedPlan);
    const paymentData = {
      amount: Math.round(formData.gst) * 100,
      currency: "INR",
      notes: {
        amount: Math.round(formData.gst),
        type: "Subscription",
        planId: selectedPlan._id,
        paymentDetails: selectedPlan.courseName,
        walletAmount: 0,
        month: numberOfMonths,
        amountWithoutGST: Math.round(formData.discountAmount),
        subject: "English",
        source: "Teacher WEB",
        availAllCourses: false,
        netPayableAmount: Math.round(formData.gst),
        postalCode: pin,
        number: phoneNumber,
        influencer: couponData?.refferalcode || null,
        dynamicDiscount: dynamicDiscount || 0 
      },
      customer: {
        name,
        ...(email && { email }),
      },
    };
    const onSuccess = (res) => {
      console.log(res);
      setLoader(false);
      message.success(res.message);
      setPaymentLink(res.data.paymentLink.short_url);
    };
    const onError = (err) => {
      setLoader(false);
      message.error(err.data.message);
      console.log(err);
    };
    dispatch(GeneratePaymentLink(paymentData, onSuccess, onError));
    form.resetFields();
  };

  const handleApplyDiscount = () => {
    if (discountValue > 50) {
      message.error("Discount cannot exceed 50%");
      return;
    }

    setDynamicDiscount(discountValue);
  
    setDisableCouponInput(true); // Disable coupon input when discount is applied
    setIsDiscountApplied(true); // Set discount applied state
  
    // Set the applied discount message
    setAppliedDiscountMessage(`A discount of ${discountValue}% has been applied.`);
  };
  
  const handleRemoveDiscount = () => {
    setDisableCouponInput(false); // Enable coupon input when discount is removed
    setIsDiscountApplied(false); // Reset discount applied state
    setAppliedDiscountMessage(""); // Clear the applied discount message
    setDiscountValue(0); // Reset discount value
    setDynamicDiscount(0);
  };
  
  const handleDiscountChange = (e) => {
    const value = Number(e.target.value);

    // Check if the value exceeds the maximum allowed discount
    if (value > 50) {
        message.error("Discount cannot exceed 50%");
    } else {
        setDiscountValue(value);
    }
};

  const [form] = Form.useForm();

  return (
    <Card title="Generate Payment Link" style={{ width: "100%", maxWidth: 600, margin: "auto", marginTop: "2rem" }}>
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={formData}
        onValuesChange={(changedValues, allValues) => {
          setFormData((prevData) => ({ ...prevData, ...changedValues }));
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Please enter your name" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Phone Number (without code)"
              name="phoneNumber"
              rules={[{ required: true, message: "Please enter your phone number" }]}
            >
              <Input
                onChange={(e) => {
                  setFormData({ ...formData, phoneNumber: e.target.value });
                  if (couponApplied) handleRemoveCoupon(); // Auto-remove coupon when phone number changes
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        {
          planLoader ?
            <div style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}>
              <Spin />
            </div>
            :
            <>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label="Plan Duration"
                    name="planDuration"
                    rules={[{ required: true, message: "Please select plan duration" }]}
                  >
                    <Select onChange={(value) => setFormData({ ...formData, planDuration: value })}>
                      {
                        uniqueDurations?.map((duration) => (
                          <Select.Option value={duration}>{duration}</Select.Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                </Col>
                {/* <Col xs={24} sm={12}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { type: "email", message: "Please enter a valid email" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col> */}
              </Row>
              {formData.planDuration && (
                <>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={12}>
                      <Form.Item
                        label="Frequency"
                        name="frequency"
                        rules={[{ required: true, message: "Please select frequency" }]}
                      >
                        <Select onChange={(value) => setFormData({ ...formData, frequency: value })}>
                          {uniqueFrequencies.map((frequency) => (
                            <Select.Option key={frequency} value={frequency}>
                              {frequency}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item
                        label="Number of Months"
                        name="numberOfMonths"
                        rules={[{ required: true, message: "Please select number of months" }]}
                      >
                        <Select onChange={(value) => setFormData({ ...formData, numberOfMonths: value })}>
                          {months?.months.map((month) => (
                            <Select.Option key={month.no} value={month.no}>
                              {month.no}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={12}>
                      {formData.amount && (
                        <Form.Item label="Amount(Without GST)">
                          <p>
                            <span style={{ fontWeight: "bold" }}>₹{Math.round(formData.amount)}</span>
                          </p>
                        </Form.Item>
                      )}
                    </Col>
                    <Col xs={24} sm={12}>
                      {formData.discountAmount && (
                        <Form.Item label="Discounted Amount(Without GST)">
                          <p>
                            <span style={{ fontWeight: "bold" }}>₹{Math.round(formData.discountAmount)}</span>
                          </p>
                        </Form.Item>
                      )}
                    </Col>
                    <Col xs={24} sm={12}>
                      {formData.discountAmount && (
                        <Form.Item label="Total Amount(With GST)">
                          <p>
                            <span style={{ fontWeight: "bold" }}>₹{Math.round(formData.gst)}</span>
                          </p>
                        </Form.Item>
                      )}
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={12}>
                      <Form.Item label="Coupon Code">
                        <Input
                          value={couponCode}
                          onChange={(e) => setCouponCode(e.target.value)}
                          disabled={disableCouponInput} // Disable input based on discount state
                        />
                        {couponApplied && <p style={{ color: "green" }}>{couponData?.message}</p>}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} style={{ textAlign: "center" }}>
                      <Form.Item label=" ">
                        {couponApplied ? (
                          <Button type="primary" onClick={handleRemoveCoupon}>
                            Remove Coupon
                          </Button>
                        ) : (
                          <Button
                            type="primary"
                            onClick={() => handleApplyCoupon(couponCode, formData)}
                            loading={loader}
                            disabled={!couponCode || disableCouponInput} // Ensure button is disabled if coupon input is disabled
                          >
                            {loader ? (
                              <>
                                <Spin /> Applying Coupon...
                              </>
                            ) : (
                              "Apply"
                            )}
                          </Button>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={12}>
                      <Form.Item label="Dynamic Discount (%)">
                        <Input
                          type="number"
                          value={discountValue}
                           onChange={handleDiscountChange}
                          max={50}
                          placeholder="Enter discount percentage"
                          disabled={isDiscountApplied} // Disable input when discount is applied
                        />
                        {appliedDiscountMessage && (
                          <p style={{ color: "green" }}>
                            {appliedDiscountMessage}

                          </p>
                        )}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} style={{ textAlign: "center" }}>
                      <Form.Item label=" ">
                      {isDiscountApplied ? (
                          <Button type="primary" onClick={handleRemoveDiscount}>
                            Remove Discount
                          </Button>
                        ) : (
                        <Button
                          type="primary"
                          onClick={handleApplyDiscount}
                          disabled={!formData.amount || !discountValue || couponApplied} // Disable if coupon applied
                        >
                          Apply Discount
                        </Button>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>


                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Form.Item>
                      {loader ? (
                        <Button type="primary" htmlType="submit" size="large" loading>
                          <Spin /> Generating Payment Link...
                        </Button>
                      ) : (
                        <Button type="primary" htmlType="submit" size="large">
                          Generate Payment Link
                        </Button>
                      )}
                    </Form.Item>
                  </div>
                </>
              )}
            </>
        }

      </Form>
      <div style={{ marginTop: 20 }}>
        {paymentLink && (
          <div style={{ backgroundColor: "#f0f0f0", padding: 10, borderRadius: 5 }}>
            <p style={{ fontSize: 16, fontWeight: "bold" }}>
              Payment Link:
              <a href={paymentLink} style={{ marginLeft: 10, color: "#1890ff" }}>
                {paymentLink}
              </a>
            </p>
          </div>
        )}
      </div>
    </Card>

  );
};

export default PaymentLink;